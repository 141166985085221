import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { Location } from '@angular/common';


@Injectable()
export class FeaturesHelper {

  public merchants = [];
	private merchant_domain: string
	private merchant_slug: string

	constructor(
		private merchantService: MerchantService,
		private translate: TranslateService,
		private userService: UserService,
		private router: Router,
		private location: Location
	) {}

	deactivateFeatures() {
		this.merchantService.pos = false;
		this.merchantService.kitchen = false;
		this.merchantService.kiosk = false;
		if (localStorage) {
			localStorage.setItem("pos", null);
			localStorage.setItem("kiosk", null);
			localStorage.setItem("kitchen", null);
		}
	}

	activatePos(domain?) {
		this.deactivateFeatures();
		this.merchantService.pos = true;
		if (localStorage) localStorage.setItem("pos", "true");
	}

	activateKitchen() {
		this.deactivateFeatures();
		this.merchantService.kitchen = true;
		if (localStorage) localStorage.setItem("kitchen", "true");
	}

	redirectToFeature(module, domain?, login = false) {
		if (domain) {
			if (domain.includes('.')) {domain = domain.substring(0, domain.indexOf('.')); }
			this.router.navigate(['/' + domain + `/${module}`], { queryParams: { login: login }});
		} else {
			this.userService.local_location = null;

			let merchants = this.userService.user.roles
			.filter( role => role.typecode == 'merchant_admin' || role.typecode == 'merchant_employee' || role.typecode == 'merchant_super_admin')
			.map( role => role.merchant )

			merchants = [...new Map(merchants.map(item => [item['id'], item])).values()];
				
			if (merchants.length == 1) {
			this.merchantService.merchant = merchants[0]
			this.router.navigate(['/' + this.merchantService.merchant.domain + `/${module}`], { queryParams: { login: login }});
			} else if (merchants.length > 1) {
				if (module == 'pos') {
					this.router.navigate(['/merchant-selection'], { queryParams: { module: `${module}`, login: login }});
				} else {
					this.router.navigate(['/merchant-selection'], { queryParams: { module: `${module}` }});
				}
			}
		}
	}

	setFeature() {
		//light or dark mode?
		this.userService.darkMode = false;

		if (localStorage && localStorage.getItem("darkMode")) {
				this.userService.darkMode = (JSON.parse(localStorage.getItem("darkMode"))).darkMode;
		}

		if (localStorage && localStorage.getItem("sortNormal")) {
			  const sortNormal = (JSON.parse(localStorage.getItem("sortNormal"))).sortNormal;
				this.userService.setSortNormal(sortNormal);
		}

		if (localStorage && localStorage.getItem("showImages")) {
			this.userService.showImages = (JSON.parse(localStorage.getItem("showImages"))).showImages;
		}

		if (localStorage && localStorage.getItem("showPrices")) {
			this.userService.showPrices = (JSON.parse(localStorage.getItem("showPrices"))).showPrices;
		}

		if (localStorage && localStorage.getItem("showSecondScreen")) {
			this.userService.showSecondScreen = (JSON.parse(localStorage.getItem("showSecondScreen"))).showSecondScreen;
		}

		if (localStorage && localStorage.getItem("fontSize")) {
			this.userService.fontSize = (JSON.parse(localStorage.getItem("fontSize"))).fontSize
		}

		if (localStorage && localStorage.getItem("posCounterId")) {
			this.userService.posCounterId = (JSON.parse(localStorage.getItem("posCounterId"))).posCounterId;
		}

		if (localStorage && localStorage.getItem("fontSizeSecondScreen")) {
			this.userService.fontSizeSecondScreen = (JSON.parse(localStorage.getItem("fontSizeSecondScreen"))).fontSizeSecondScreen
		}

		if (localStorage && localStorage.getItem("showPricesConsumerScreen")) {
			this.userService.showPricesConsumerScreen = (JSON.parse(localStorage.getItem("showPricesConsumerScreen")))
		}

		this.merchantService.kiosk = false;
		this.merchantService.pos = false;
		this.merchantService.kitchen = false;

		//kiosk?
    	if (localStorage && localStorage.getItem("kiosk")) { 
    		try {
    			this.merchantService.kiosk = JSON.parse(localStorage.getItem("kiosk"));
    			if (this.merchantService.kiosk && this.merchantService.kiosk.locale) this.translate.use(this.merchantService.kiosk.locale);
    		} catch (error) {
    			if (localStorage) localStorage.setItem("kiosk", null);
    		}
    	
    	} else if (localStorage && localStorage.getItem("pos")) { 
    		//no action needed?
    	}  else if (localStorage && localStorage.getItem("kitchen")) { 
    		this.merchantService.kitchen = JSON.parse(localStorage.getItem("kitchen"));
    	}


	}


}